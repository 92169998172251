@import "colors";

.p-dropdown-panel {
  background: white;
  z-index: 500000;
}

.p-dropdown-items-wrapper ul, .p-multiselect-items-wrapper ul {
  padding-left: 0;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: none !important;
}

.p-link:focus {
  box-shadow: $color-primary-boxshadow;
}

// confirmpopup

.p-confirm-popup {
  z-index: 11001 !important; // show above swals
  max-width: 90%;

  @media(min-width: 700px) {
    max-width: 500px;
  }

  .p-confirm-popup-reject {
    color: $color-primary;

    &:hover, &active, &:focus {
      color: $color-primary !important;
      box-shadow: none;
      background-color: rgba($color-primary, 0.8) !important;
    }
  }

  .p-confirm-popup-accept {
    background: $color-primary;
    border-color: $color-primary-dark;
    box-shadow: none !important;

    &:hover, &:active {
      background: $color-primary-dark;
      border-color: $color-primary-darker;
    }
  }
}


.p-sortable-column-icon {
  width: 1.28571429em;
  margin-left: 3px;
  margin-right: 3px;
}

// icons
.pi {
  font: normal normal normal 1rem FontAwesome;

  &.pi-sort-amount-down:before {
    content: "\f161";
  }

  &.pi-sort-amount-up-alt:before {
    content: "\f160";
  }

  &.pi-sort-alt:before {
    content: "\f0dc";
    margin-right: 3px;
  }

  &.pi-times:before {
    content: "\f00d";
  }

  &.pi-check:before {
    content: "\f00c";
  }

  &.pi-chevron-up:before {
    content: "\f077";
  }

  &.pi-chevron-left:before {
    content: "\f053";
  }

  &.pi-chevron-right:before {
    content: "\f054";
  }

  &.pi-chevron-down:before {
    content: "\f078";
  }
}

// treeselect
body .p-tree .p-tree-container .p-treenode .p-treenode-content {

  .p-tree-toggler:focus {
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;
  }
}

.p-editor-content .ql-editor {
  font-size: 1rem;
}

// galleria
.p-galleria {
  @media(max-width: 750px) {
    .p-galleria-item-nav {
      width: 1rem;
      margin: 0.1rem;
    }
  }

  .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: $color-primary;
    color: #ffffff;
  }

  .p-galleria-item-nav {
    background: none !important;
    box-shadow: none !important;

    &:hover:not([disabled]) {
      color: $color-primary;
    }
  }

  .p-galleria-thumbnail-container {
    background-color: rgba(37, 37, 37, 0.04);
    border-top: 1px solid #dee2e6;
  }
}

// carousel
.p-carousel {
  @media(max-width: 600px) {
    .p-carousel-container .p-carousel-next, .p-carousel-container .p-carousel-prev {
      width: 0.5rem;
      margin: 0.1rem;
    }
  }

  .p-carousel-item {
    align-self: center;
  }

  .p-carousel-container .p-carousel-next:enabled:hover, .p-carousel-container .p-carousel-prev:enabled:hover {
    color: $color-primary;
  }

  .p-carousel-prev:focus, .p-carousel-next:focus {
    box-shadow: none !important;
  }

  .p-carousel-prev-icon.pi {
    font: normal normal normal 1.5rem FontAwesome;

    &.pi-chevron-left:before {
      content: "\f0d9";
    }

    &.pi-chevron-right:before {
      content: "\f0da";
    }

  }

  .p-carousel-indicators .p-carousel-indicator {
    &.p-highlight button {
      background: $color-primary;
      color: #ffffff;
    }

    button:focus, button:active {
      box-shadow: $color-primary-boxshadow;
    }
  }
}

// editor
p-editor.ng-dirty.ng-invalid {
  .p-editor-container {
    .p-editor-toolbar {
      border-color: #FF4A55;
      border-bottom-color: #c8c8c8;
    }

    .p-editor-content {
      border-color: #FF4A55;
    }
  }
}

// sidebar
.p-sidebar {

  .p-sidebar-header {
    display: none;
  }

  .p-sidebar-content {
    height: 100%;
  }
}

//p-listbox
.p-listbox-list .p-listbox-item {
  &.p-highlight {
    background-color: $color-primary !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

// tooltip
.p-tooltip {
  .p-tooltip-text {
    border: 1px solid gray;
    background-color: white;
    color: black;
    font-size: 0.85em;
  }

  &.p-tooltip-right .p-tooltip-arrow {
    border-right-color: gray;
  }

  &.p-tooltip-left .p-tooltip-arrow {
    border-left-color: gray;
  }

  &.p-tooltip-top .p-tooltip-arrow {
    border-top-color: gray;
  }

  &.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: gray;
  }
}


// tabview
.p-tabview {
  .p-tabview-panels {
    padding-top: 1rem !important;
    border: none !important;
  }

  .p-tabview-nav {
    border-bottom: none !important;
    background: transparent;
    margin-bottom: 0;

    .p-tabview-nav-link {
    }

    .p-tabview-ink-bar {
      z-index: 1;
      display: block;
      position: absolute;
      bottom: 0;
      height: 2px;
      transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
      background-color: $color-primary;
    }

    li {
      .p-tabview-nav-link {
        background: transparent;
        padding: 0.5rem 0.6rem !important;
        border: none !important;
        text-decoration: none;

        &:not(.p-highlight) {
          color: gray;
        }

        .fa {
          padding: 4px 0;
        }
      }

      a {
        background-color: transparent !important;
      }

      &.p-highlight a {
        color: $color-primary !important;
      }
    }

    a {
      border-radius: 0 !important;
      transition: all .2s !important;

      &:focus {
        box-shadow: none !important;
      }
    }
  }
}

.p-card .p-card-body {

  .p-card-content {
    padding: 0;
  }
}

// overlaypanel
body .p-overlaypanel {
  z-index: 11000 !important;

  .p-overlaypanel-close {
    border-radius: 25px;
    background: $color-primary;
  }
}

// dropdown-overlay
body .p-overlay {
  z-index: 11000 !important;
}

body .p-inputtext {
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 5px !important;
  background: none;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25) !important;
  }
}

// multiselect

body p-multiSelect {
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background-color: white;
    color: black;

    .p-accordion-header-text {
      pointer-events: none;
    }
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: $color-primary-dark;
    background: $color-primary;

    &:hover {
      border-color: $color-primary-darker !important;
      background: $color-primary-dark !important;;
    }
  }
}

// calendar
p-calendar, .p-calendar {
  display: block;
  width: 100%;

  input {
    width: calc(100% - 38px) !important;
  }

  .p-datepicker-trigger {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

body .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
  background-color: $color-primary;
  height: 38px;
  border-color: $color-primary-dark;

  &:hover {
    background-color: $color-primary-dark;
  }

  .p-button-text {
    margin-bottom: -3px;
  }
}

.p-calendar.p-calendar-w-btn .p-inputtext {
  border: 1px solid #ced4da;
  border-right: none !important;
  border-radius: .25rem 0 0 .25rem;
  outline: none !important;
  height: calc(1.5em + .75rem + 2px);

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
  }

  &:hover {
    border: 1px solid #ced4da !important;
    border-right: none !important;
  }
}

.p-button.p-button-icon-only .p-button-text {
  padding: .48em !important;
}

body .p-inputswitch {
  &.p-inputswitch-focus .p-inputswitch-slider {
    box-shadow: none;
  }

  &.p-inputswitch-checked {
    &:hover .p-inputswitch-slider, &:active .p-inputswitch-slider {
      background-color: #218d3a !important;
      // background-color: $color-primary-hover !important;
    }

    .p-inputswitch-slider {
      background-color: #259b40 !important;
      // background-color: $color-primary;
    }

    &.p-inputswitch-focus .p-inputswitch-slider {
      background-color: #259b40 !important;
      // background-color: $color-primary;
    }
  }
}

// accordion
body p-accordion, .p-accordion {
  a.p-accordion-header-link {
    text-decoration: none;
    color: black;

    * {
      pointer-events: none;
    }
  }

  * {
    box-shadow: none !important;
  }

  .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background-color: $color-primary;
    border: 1px solid $color-primary-dark;
    color: #fff;

    &:hover, &:active {
      background-color: $color-primary-dark;
      border: 1px solid $color-primary;
    }
  }


  &.borderless {
    .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link, .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link.p-highlight {
      color: black;

      &:hover {
        color: black;
      }
    }

    .p-accordion-header .p-accordion-header-link {
      background-color: transparent !important;
      border: 1px solid transparent !important;
      box-shadow: none !important;
    }


    .p-accordion-content {
      border: none;
    }
  }

}


span.p-submenu-icon.pi.pi-angle-right {
  &::before {
    content: "\f0da";
    font: normal normal normal 14px/1 FontAwesome;
  }
}

.p-dialog-mask > div.console-overlay {
  height: 90%;
  width: 90%;
}

.p-dialog-mask > div.full-screen {
  max-height: none;
  height: 100%;
  width: 100%;
}

p-dropdown .p-dropdown-label {
  padding-top: 6px;
}

input-checkbox.input-sm, input-textbox.input-sm *, input-multiselect.input-sm p-multiselect *, input-dropdown.input-sm label {
  font-size: .8rem;
}

input-checkbox.input-sm {
  .custom-control-description {
    margin-top: 2px;
  }

  .custom-control-indicator {
    transform: scale(0.8);
  }
}


p-splitbutton {
  button {
    padding: 0.22rem 0.575rem;
  }

  button.p-splitbutton-defaultbutton {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: 0.875rem;
    background: var(--color-primary);
    border-color: var(--color-primary-dark);
    color: white;

    &:hover {
      background: var(--color-primary-dark);
    }
  }

  button.p-splitbutton-menubutton {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: white;
    color: var(--color-primary);
    border-color: var(--color-primary-dark);
    width: 2.1rem
  }
}

.p-tieredmenu-overlay {
  width: 300px;

  .p-menuitem-link {
    padding: 0.657rem;
    font-size: 0.9em;
  }
}

p-tieredmenusub {
  white-space: nowrap;

  ul {
    padding-left: 0;
  }

  a {
    text-decoration: none
  }
}